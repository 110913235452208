@tailwind base;

/* @import '@fontsource/dm-sans'; */
@import '@fontsource/roboto';
@import 'react-virtualized/styles.css';
@import './variables.css';

@tailwind components;

@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: none;
}

input:focus {
  box-shadow: none;
  outline: none;
}

.striped-background {
  background: repeating-linear-gradient(45deg, #151d22, #1e2630 10px, #072025 10px, #0b2e36 20px);
}


input[type=datetime-local]::-webkit-calendar-picker-indicator {
  background-image: url('../../public/images/datepicker-icon.svg')
}

/* if backdrop support: very transparent and blurred */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur-fallback {
    background-color: var(--primary);
  }
}


.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
